import { Component, HostListener, OnDestroy } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import Konva from 'konva';
import { Layer } from 'konva/types/Layer';
import { Stage } from 'konva/types/Stage';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Actions } from 'src/app/consts/actions';
import { ConfigConsts } from 'src/app/consts/configConsts';
import { LogConsts } from 'src/app/consts/logConsts';
import { StorageConsts } from 'src/app/consts/storage';
import { WallConsts } from 'src/app/consts/wallConsts';
import { Configuration } from 'src/app/model/configuration';
import { GeneralSettings } from 'src/app/model/generalSettings';
import { Product } from 'src/app/model/product';
import { ProductInteractionConfig } from 'src/app/model/productInteractionConfig';
import { Setting } from 'src/app/model/setting';
import { SidebarSettings } from 'src/app/model/sidebarSettings';
import { Validation } from 'src/app/model/validation';
import { Wall } from 'src/app/model/wall';
import { AlertService } from 'src/app/services/alert.service';
import { AuthorisationService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { PdfService } from 'src/app/services/pdf.service';
import { ProductService } from 'src/app/services/product.service';
import { ProductOrderService } from 'src/app/services/productorder.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DrawingConfigExtractor } from 'src/app/utils/drawingConfigExtractor';
import { FictionalProductUtils } from 'src/app/utils/fictionalUtils';
import { Measurements } from 'src/app/utils/measurements';
import { OrderUtils } from 'src/app/utils/orderUtils';
import { PdfUtil } from 'src/app/utils/pdfUtil';
import { PositionNumberUtil } from 'src/app/utils/positionNumberUtil';
import { ProductInteractionUtils } from 'src/app/utils/producInteractionUtils';
import { ProductUtils } from 'src/app/utils/productUtils';
import { SaveUtil } from 'src/app/utils/saveUtil';
import { Snap } from 'src/app/utils/snap';
import { SubProductsUtil } from 'src/app/utils/subproductsUtil';
import { ValidationUtils } from 'src/app/utils/validationUtils';
import { WallUtils } from 'src/app/utils/wallUtils';
import { ZoomUtil } from 'src/app/utils/zoomUtil';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnDestroy {
  stage: Stage;
  layers: Layer[] = [];
  activeLayer: Layer;
  bin: Product[] = [];
  validations: Validation[] = [];
  layerIndex = 0;
  autoSaveIterator = 0;
  showOuterMeasurements = false;
  showInnerMeasurements = false;
  showNames = false;
  showDrawerAlign = false;
  showNewCabins = false;
  filterOn = false;
  generatePDFActive = false;
  generalSettings: GeneralSettings;
  sidebarSettings: SidebarSettings;
  config: Configuration;
  measurementFilterOn = false;
  editDrawing: boolean;

  allowAutoLocalsave = false;
  allowAutosave = false;
  allowSave = true;
  allowDraw = false;

  productConfig: ProductInteractionConfig = { connectToAnother: false };

  dekorlistRows: any;
  sockelRows: any;

  designs: any;

  newWallEmitterSub;
  LoadOrderEmitterSub;
  permissionsEmitterSub;
  validationEmitterSub;
  newProductEmitterSub;
  newFictionalProductEmitterSub;
  configEmitterSub;
  wallSwitchEmitterSub;
  wallEditEmitterSub;
  measurementsEmitterSub;
  sidebarSettingsEmitterSub;
  switchLayersEmitterSub;
  generalSettingsEmitterSub;
  binEmitterSub;
  loadActionEmitterSub;
  productActionEmitterSub;
  stageActionEmitterSub;
  productConfigEmitterSub;

  order: any;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService,
    private productOrderService: ProductOrderService,
    private validationService: ValidationService,
    private alertService: AlertService,
    private pdfService: PdfService,
    private storage: LocalStorageService,
    private authService: AuthorisationService,
    private logService: LogService,
    private deviceService: DeviceDetectorService
  ) {
    this.LoadOrderEmitterSub =
      this.productOrderService.LoadOrderEmitter.subscribe((order) => {
        this.designs = order.drawingDesigns;
        this.createNewOrder(order);
        this.allowAutoLocalsave = true;
        setTimeout(() => {
          this.allowAutosave = false;
        }, 30000);
        if (this.validations) {
          this.validationService.simpleValidationEmitter.emit(
            ValidationUtils.getAvailableSimpleValidations(
              this.order,
              this.validations
            )
          );
        }
      });

    this.permissionsEmitterSub = this.authService.permissionsEmitter.subscribe(
      (permissions: any) => {
        if (permissions) {
          this.editDrawing = permissions.editDrawing;
        }
      }
    );

    this.validationEmitterSub =
      this.validationService.validationEmitter.subscribe(
        (validations: Validation[]) => {
          this.validations = validations;
          if (this.order) {
            this.validationService.simpleValidationEmitter.emit(
              ValidationUtils.getAvailableSimpleValidations(
                this.order,
                this.validations
              )
            );
          }
        }
      );

    this.newWallEmitterSub = this.navbarService.newWallEmitter.subscribe(
      (wall: Wall) => {
        this.createNewWall(wall);
        this.allowAutosave = true;
      }
    );
    this.newProductEmitterSub = this.navbarService.newProductEmitter.subscribe(
      (product: Product) => {
        ProductUtils.createNewProduct(
          product,
          this.activeLayer,
          this.generalSettings
        );
        this.allowDraw = true;
        this.allowAutosave = true;
      }
    );

    this.newFictionalProductEmitterSub =
      this.navbarService.newFictionalProductEmitter.subscribe(
        (fictionalProduct) => {
          FictionalProductUtils.createNewFictionalProduct(
            fictionalProduct,
            this.activeLayer,
            this.generalSettings,
            this.config.fixedDimensions === ConfigConsts.YES
          );
          this.allowDraw = true;
          this.allowAutosave = true;
          // tslint:disable-next-line: no-empty
          this.logService
            .postLog(LogConsts.INFO, 'Created new fictional product')
            .subscribe(() => {});
        }
      );

    this.binEmitterSub = this.navbarService.binEmitter.subscribe(
      (bin: Product[]) => {
        this.bin = bin;
        this.allowAutosave = true;
      }
    );
    // maybe redefine wall switch functionality
    this.wallSwitchEmitterSub = this.navbarService.wallSwitchEmitter.subscribe(
      (next) => {
        if (next) {
          this.nextLayer();
        } else {
          this.previousLayer();
        }
      }
    );
    this.measurementsEmitterSub =
      this.navbarService.measurementsEmitter.subscribe((config) => {
        this.showOuterMeasurements = config.outer;
        this.showInnerMeasurements = config.inner;
        this.showNames = config.tags;
        this.showDrawerAlign = config.drawerAlign;
        this.measurementFilterOn = config.filterOn;
        this.showNewCabins = config.newCabins;
        this.allowDraw = true;
      });

    this.sidebarSettingsEmitterSub =
      this.navbarService.sidebarSettingsEmitter.subscribe((settings) => {
        this.sidebarSettings = settings;
      });

    this.switchLayersEmitterSub =
      this.navbarService.switchLayersEmitter.subscribe((index) => {
        this.switchWalls(index);
      });

    this.generalSettingsEmitterSub =
      this.navbarService.generalSettingsEmitter.subscribe(
        (gSettings: GeneralSettings) => {
          ProductUtils.updateOrderSettings(this.order, gSettings);
          this.generalSettings = gSettings;
          if (
            this.generalSettings.settings.find(
              (setting: Setting) => setting.value === '--SPECIAL--'
            )
          ) {
            this.stage.find('.product').each((product: Product) => {
              product.changeValue('COMMENTS', '--SPECIAL--');
            });
          }
          if (
            this.generalSettings.settings.find(
              (setting: Setting) => setting.value === '--NORMAL--'
            )
          ) {
            this.stage.find('.product').each((product: Product) => {
              product.changeValue('COMMENTS', '');
            });
          }
        }
      );

    this.configEmitterSub = this.navbarService.configEmitter.subscribe(
      (x: Configuration) => {
        this.config = x;
        this.allowDraw = true;
        this.allowAutosave = true;
      }
    );

    this.productConfigEmitterSub =
      this.navbarService.productConfigEmitter.subscribe(
        (x: ProductInteractionConfig) => {
          this.productConfig = x;
        }
      );

    this.loadActionEmitterSub = this.navbarService.loadActionEmitter.subscribe(
      (x) => {
        // tslint:disable-next-line: no-empty
        this.logService
          .postLog(LogConsts.INFO, 'Performing load local')
          .subscribe(() => {});
        this.loadLocal(x);
      }
    );

    this.wallEditEmitterSub = this.navbarService.wallEditEmitter.subscribe(
      (w1: Wall) => {
        if (w1.recentlyEdited) {
          ProductInteractionUtils.fitToSockel(w1.parent as Layer);
          w1.recentlyEdited = false;
        }
      }
    );

    this.productActionEmitterSub =
      this.navbarService.productActionEmitter.subscribe((action) => {
        switch (action) {
          case Actions.CANCEL:
            this.cancelAction();
            break;
          case Actions.DELETE:
            this.deleteAction();
            break;
          case Actions.COPY:
            this.copyAction();
            break;
          case Actions.CLICK:
            this.resetClickAction(this.activeLayer);
            break;
          case Actions.REDRAW:
            this.redrawAction();
            break;
        }
        this.allowDraw = true;
      });

    this.stageActionEmitterSub =
      this.navbarService.stageActionEmitter.subscribe((action) => {
        switch (action) {
          case Actions.SAVE_LOCALLY:
            // tslint:disable-next-line: no-empty
            this.logService
              .postLog(LogConsts.INFO, 'Performing save locally')
              .subscribe(() => {});
            this.saveLocally(false);

            break;
          case Actions.SAVE_ORDER:
            // tslint:disable-next-line: no-empty
            this.logService
              .postLog(LogConsts.INFO, 'Performing save order')
              .subscribe(() => {});
            this.saveOrder(false, false, false);

            break;
          case Actions.SAVE_AND_PDF:
            // tslint:disable-next-line: no-empty
            this.logService
              .postLog(LogConsts.INFO, 'Performing save and create pdf')
              .subscribe(() => {});
            this.saveOrder(true, true, false);

            break;
          case Actions.SAVE_AND_RETURN:
            // tslint:disable-next-line: no-empty
            this.logService
              .postLog(LogConsts.INFO, 'Performing save and return')
              .subscribe(() => {});
            this.saveOrder(false, true, false);

            break;
          case Actions.AUTOSAVE:
            // tslint:disable-next-line: no-empty
            this.logService
              .postLog(LogConsts.INFO, 'Performing autosave')
              .subscribe(() => {});
            this.saveOrder(false, false, true);

            break;
          case Actions.RETURN_TO_HK:
            // tslint:disable-next-line: no-empty
            this.logService
              .postLog(LogConsts.INFO, 'Returning to HK')
              .subscribe(() => {});
            this.returnToHK();
            break;
          case Actions.PDF_ONLY:
            // tslint:disable-next-line: no-empty
            this.logService
              .postLog(LogConsts.INFO, 'Performing generate pdf')
              .subscribe(() => {});
            this.generatePdf();
            break;
          case Actions.ZOOM:
            this.onZoom();
            break;
          case Actions.CLEANUP_LAYERS:
            this.cleanupLayers();
            break;
          case Actions.CREATE_STAGE:
            this.stage = this.initStage();
            break;
          case Actions.REDRAW:
            this.redrawAction();
            break;
          case Actions.GENERATE_PDF:
            this.generatePDFActive = true;
            break;
        }
      });

    // calculations
    requestAnimationFrame(() => {
      if (this.allowDraw) {
        this.allowDraw = false;
        this.drawMeasurements();
      }
    });

    setInterval(() => {
      this.drawMeasurements();
    }, 1000);

    // autosave
    setInterval(() => {
      if (this.allowAutoLocalsave) {
        // could be also Action.AUTOSAVE_LOCAL
        this.saveLocally(true);
        this.autoSaveIterator += 1;
        if (
          this.autoSaveIterator >= Number(this.config.autoSave) &&
          this.allowAutosave
        ) {
          this.allowAutosave = false;
          this.navbarService.stageActionEmitter.emit(Actions.AUTOSAVE);
          this.autoSaveIterator = 0;
        }
      }
    }, 60000); // 1 min -> 60000 msec
  }

  ngOnDestroy(): void {
    this.newWallEmitterSub.unsubscribe();
    this.LoadOrderEmitterSub.unsubscribe();
    this.permissionsEmitterSub.unsubscribe();
    this.validationEmitterSub.unsubscribe();
    this.newProductEmitterSub.unsubscribe();
    this.newFictionalProductEmitterSub.unsubscribe();
    this.configEmitterSub.unsubscribe();
    this.wallSwitchEmitterSub.unsubscribe();
    this.measurementsEmitterSub.unsubscribe();
    this.sidebarSettingsEmitterSub.unsubscribe();
    this.switchLayersEmitterSub.unsubscribe();
    this.generalSettingsEmitterSub.unsubscribe();
    this.binEmitterSub.unsubscribe();
    this.loadActionEmitterSub.unsubscribe();
    this.productActionEmitterSub.unsubscribe();
    this.stageActionEmitterSub.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.stage.setAttrs({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    this.onZoom();
  }

  cancelAction(): void {
    const deactivatedProducts = [];
    this.activeLayer.find('.product').each((product: Product) => {
      product.isActive = false;
      product.updateBorderColors();
      deactivatedProducts.push(product);
    });
    this.productService.productsEmitter.emit(deactivatedProducts);
    this.activeLayer.draw();
  }

  deleteAction(): void {
    const undeletedProducts = [];
    this.activeLayer.find('.product').each((product: Product) => {
      if (product.isActive) {
        product.subProducts.forEach((sub: Product) => {
          this.navbarService.newBinProductEmitter.emit(sub);
          sub.destroy();
        });
        product.subProducts = [];
        if (!product.isFictional) {
          this.navbarService.newBinProductEmitter.emit(product);
        } else {
          ProductInteractionUtils.fictionalProductsInteractionAfterDelete(
            this.activeLayer,
            product
          );
        }
        product.destroy();
      } else {
        undeletedProducts.push(product);
      }
    });
    this.productService.productsEmitter.emit(undeletedProducts);
    this.allowAutosave = true;
    this.activeLayer.draw();
  }

  copyAction(): void {
    this.activeLayer.find('.product').each((product: Product) => {
      if (product.isActive) {
        ProductUtils.createNewProduct(
          product,
          this.activeLayer,
          this.generalSettings
        );
      }
    });
  }

  redrawAction(): void {
    this.stage.draw();
    this.allowAutosave = true;
  }

  clickAction(layer: Layer, event: any): void {
    this.clickOnProduct(layer, event);
    this.allowDraw = true;
    if (layer === this.activeLayer && !this.productConfig.connectToAnother) {
      const products = [];
      layer.find('.product').each((product: Product) => {
        products.push(product);
      });
      this.allowDraw = true;
      this.productService.productsEmitter.emit(products);
    }
    this.allProductsEmit();
  }

  allProductsEmit(): void {
    if (!this.layers) {
      return;
    }
    const listOfroducts = [];
    this.layers.forEach((layer: Layer) => {
      layer.find('.product').each((product: Product) => {
        listOfroducts.push(product);
      });
    });
    this.productService.allProductsEmitter.emit(listOfroducts);
  }

  clickOnProduct(layer, event): void {
    // review this abomination
    console.log(this.productConfig?.connectToAnother);
    this.resetClickAction(layer);
    this.allowDraw = true;
    if (event.target.isWall) {
      return;
    }
    if (event.target.attrs.container) {
      return;
    }
    if (event.target.parent.isProduct || event.target.parent.isWall) {
      if (event.target.parent.isWall) {
        return;
      }
      event.target.parent.updateOnClick(this.productConfig?.connectToAnother);
    } else if (
      event.target.parent.parent.isProduct ||
      event.target.parent.parent.isWall
    ) {
      if (event.target.parent.parent.isWall) {
        return;
      }
      event.target.parent.parent.updateOnClick(
        this.productConfig?.connectToAnother
      );
    } else if (event.target.parent.parent.parent.isProduct) {
      if (event.target.parent.parent.parent.isWall) {
        return;
      }
      event.target.parent.parent.parent.updateOnClick(
        this.productConfig?.connectToAnother
      );
    } else if (event.target.parent.parent.parent.parent.isProduct) {
      if (event.target.parent.parent.parent.parent.isWall) {
        return;
      }
      event.target.parent.parent.parent.parent.updateOnClick(
        this.productConfig?.connectToAnother
      );
    }
  }

  resetClickAction(layer: Layer): void {
    if (!this.productConfig?.connectToAnother) {
      layer.find('.product').each((p: Product) => {
        p.isActive = true;
        p.updateOnClick(false);
      });
    }
  }

  onZoom(): void {
    if (!this.stage) {
      return;
    }
    if (this.sidebarSettings.manualZoom) {
      ZoomUtil.zoom(this.stage, this.sidebarSettings);
    }
    if (this.sidebarSettings.automaticZoom) {
      WallUtils.locateWall(this.stage, this.activeLayer);
      this.sidebarSettings.zoom = ZoomUtil.zoomToFit(
        this.stage,
        this.activeLayer
      );
    }
    if (this.sidebarSettings.automaticZoomOut) {
      this.sidebarSettings.zoom = ZoomUtil.zoomAllToFit(this.stage);
    }
    this.stage.draw();
    this.redrawAction();
    this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);
    this.allowDraw = true;
  }

  cleanupLayers(): void {
    this.layers.forEach((layer, i) => {
      let destroyLayer = true;
      layer.find('.wall').each((wall) => {
        destroyLayer = false;
      });
      if (destroyLayer) {
        layer.find('.product').each((product: Product) => {
          product.subProducts.forEach((sub: Product) => {
            this.navbarService.newBinProductEmitter.emit(sub);
            sub.destroy();
          });
          product.subProducts = [];
          this.navbarService.newBinProductEmitter.emit(product);
        });
        layer.destroy();
        this.layers.splice(i, 1);
        if (i < this.layerIndex) {
          this.layerIndex -= 1;
        }
      }
    });

    if (!this.activeLayer.children[0]) {
      this.switchWalls(0);
    } else {
      this.switchWalls(this.layerIndex);
    }
    WallUtils.wallSort(this.layers);

    this.navbarService.layerEmitter.emit(this.activeLayer);
    this.navbarService.layersEmitter.emit(this.layers);
    this.allowDraw = true;
    this.activeLayer.draw();
  }

  switchWalls(layerIndex): void {
    if (this.activeLayer) {
      this.layerIndex = layerIndex;
      this.activeLayer.find('.product').each((product) => {
        product.listening(false);
      });
    }
    this.activeLayer = this.layers[layerIndex];
    this.stage.add(this.activeLayer);
    this.activeLayer.find('.product').each((product) => {
      product.listening(true);
    });
    WallUtils.wallSort(this.layers);
    WallUtils.locateWall(this.stage, this.activeLayer);
    this.onZoom();
    this.navbarService.layerEmitter.emit(this.activeLayer);
    this.allowDraw = true;
  }

  createNewWall(wall: Wall): void {
    this.createNewLayer(wall.selectedSide);
    if (wall.selectedSide === 'R') {
      this.layerIndex = this.layers.length - 1;
    } else {
      this.layerIndex = 0;
    }
    this.switchWalls(this.layerIndex);
    this.activeLayer.add(
      new Wall(wall.attrs, wall.wallName, wall.selectedSide, wall.sockelHeight)
    );
    WallUtils.wallSort(this.layers);
    this.onZoom();
    this.navbarService.layerEmitter.emit(this.activeLayer);
    this.navbarService.layersEmitter.emit(this.layers);
  }

  createNewLayer(side): void {
    const layer = new Konva.Layer();
    if (side === 'L') {
      this.layers.unshift(layer);
    } else {
      this.layers.push(layer);
    }
  }

  saveLocally(autoSave): void {
    this.productService.saveLocally(this.order, autoSave);
    this.navbarService.stageActionEmitter.emit(Actions.UPDATE_LOCAL_SAVE);
  }

  loadLocal(num): void {
    const data = this.productService.loadLocalSave();
    const y = SaveUtil.updateOrder(this.order, data, num);
    const x = y.successfull;
    this.order = y.order;
    if (x) {
      // tslint:disable-next-line: no-empty
      this.logService
        .postLog(LogConsts.INFO, 'Loading local save')
        .subscribe(() => {});
      this.layers.forEach((layer) => {
        layer.destroy();
      });
      this.layers = [];
      this.navbarService.binEmitter.emit([]);
      this.bin = null;
      this.createNewOrder(this.order);
    } else {
      // tslint:disable-next-line: no-empty
      this.logService
        .postLog(LogConsts.ERROR, 'Cannot find local save')
        .subscribe(() => {});
      alert('Det finns ingen lokal spara');
    }
  }

  createNewOrder(order): void {
    if (!order) {
      return null;
    }

    this.order = order;
    this.layers.forEach((layer) => {
      layer.destroy();
    });
    this.layers = [];
    this.bin = [];
    this.navbarService.binEmitter.emit([]);

    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Initialising canvas')
      .subscribe(() => {});

    if (!this.order.productOrderWalls) {
      // tslint:disable-next-line: no-empty
      this.logService
        .postLog(LogConsts.INFO, 'Creating empty wall')
        .subscribe(() => {});
      this.navbarService.newWallEmitter.emit(
        new Wall(
          {
            x: 0,
            y: 0,
            width: WallConsts.INITIAL_WIDTH,
            height: WallConsts.INITIAL_HEIGHT,
          },
          'Huvudvägg',
          'L',
          WallConsts.INITIAL_SOCKEL
        )
      );
    } else {
      // tslint:disable-next-line: no-empty
      this.logService
        .postLog(LogConsts.INFO, 'Loading walls from order')
        .subscribe(() => {});
      this.order.productOrderWalls
        .sort((a, b) => a.wallID - b.wallID)
        .forEach((orderWall) => {
          this.navbarService.newWallEmitter.emit(
            new Wall(
              {
                x: 0,
                y: 0,
                width: orderWall.width,
                height: orderWall.height,
              },
              orderWall.name,
              'R',
              orderWall.sockelHeight
                ? orderWall.sockelHeight
                : WallConsts.INITIAL_SOCKEL
            )
          );
        });
    }

    this.order.productOrderRows.forEach((prod) => {
      if (prod.fictional || prod.fictionalID !== null) {
        FictionalProductUtils.restoreFictionalProduct(
          prod,
          this.layers[prod.wallID],
          this.config.fixedDimensions === ConfigConsts.YES
        );
      } else {
        const newProd = ProductUtils.createProductFromOrder(prod, this.designs);

        if (!newProd || !newProd.attrs || !prod.product.visibleInDrawing) {
          return;
        }
        newProd.attrs.id = prod.productOrderRowID;

        if (prod.wallID === null || prod.wallID >= this.layers.length) {
          this.navbarService.newBinProductEmitter.emit(
            new Product(
              newProd.attrs,
              newProd.type,
              newProd.handlePlacement,
              newProd.isBottom,
              newProd.settings,
              newProd.isFictional,
              newProd.isVisibleInDrawing,
              newProd.canBeSubproduct,
              newProd.additionalInfo,
              newProd.subcategoryID,
              newProd.productID,
              newProd.graphicFileID,
              newProd.design
            )
          );
        } else {
          ProductUtils.restoreProduct(
            newProd,
            this.layers[prod.wallID],
            this.config.fixedDimensions === ConfigConsts.YES
          );
        }
      }
    });

    SubProductsUtil.assignSubProducts(this.layers);
    this.navbarService.generalSettingsEmitter.emit(this.generalSettings);

    this.dekorlistRows = OrderUtils.getRowsBySetting(
      this.order,
      'DESIGN_OF_PRODUCT',
      'LIGHTRAMPSTRIP'
    );
    this.sockelRows = OrderUtils.getRowsBySetting(
      this.order,
      'DESIGN_OF_PRODUCT',
      'SOCKEL'
    );
    this.allowDraw = true;

    if (this.generatePDFActive) {
      this.generatePdf();
    }
  }

  prepareForSave(a: boolean): void {
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Preparing for save')
      .subscribe(() => {});
    if (!a) {
      this.navbarService.spinnerActionEmitter.emit({
        action: 'add',
        text: 'PREPARING_FOR_SAVE',
      });
    }
    this.order.productOrderWalls = [];
    this.order.productOrderRows = this.order.productOrderRows.filter(
      (row) => !row.fictional
    );
    this.order.productOrderRows = this.order.productOrderRows.filter(
      (row) => !row.isFictional
    );
    this.layers.forEach((layer: Layer, index) => {
      layer.find('.product').each((product: Product) => {
        ProductUtils.updateDataFromProduct(product, this.order, index);
      });
      layer.find('.wall').each((wall: Wall) => {
        WallUtils.updateDataFromWall(wall, this.order, index);
      });
    });
    if (!this.bin) {
      this.bin = [];
    }
    this.bin.forEach((product) => {
      ProductUtils.updateDataFromProduct(product, this.order, null);
    });

    this.order.drawingConfig = DrawingConfigExtractor.stringifyConfig(
      this.config,
      this.generalSettings
    );
  }

  saveOrder(r: boolean, s: boolean, a: boolean): void {
    this.allowSave = false;
    this.prepareForSave(a);
    this.saveLocally(false);
    if (!a) {
      this.navbarService.spinnerActionEmitter.emit({
        action: 'add',
        text: 'SAVED_LOCALLY',
      });
    }
    if (!a) {
      this.navbarService.spinnerActionEmitter.emit({
        action: 'add',
        text: 'SAVING',
      });
    }
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Sending order to HK')
      .subscribe(() => {});
    if (!a && s) {
      this.productOrderService.setProductOrder(this.order).subscribe(
        (response) => {
          this.afterSave(response, r, s, a);
        },
        (error) => {
          this.afterErrorSave(error, r, s, a);
        }
      );
    } else {
      this.productOrderService.setAutoProductOrder(this.order).subscribe(
        (response) => {
          this.afterSave(response, r, s, a);
        },
        (error) => {
          this.afterErrorSave(error, r, s, a);
        }
      );
    }
  }

  afterSave(response, r: boolean, s: boolean, a: boolean): void {
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Order saved successfully')
      .subscribe(() => {});
    if (r) {
      this.generatePdf();
    } else if (s) {
      if (!a) {
        this.navbarService.spinnerActionEmitter.emit({
          action: 'add',
          text: 'SAVE_COMPLETE',
        });
      }
      this.navbarService.stageActionEmitter.emit(Actions.SAVE_COMPLETE);
      this.productOrderService.orderSent();
    } else {
      this.navbarService.stageActionEmitter.emit(Actions.SAVE_COMPLETE);
      if (!a) {
        this.navbarService.spinnerActionEmitter.emit({
          action: 'reset',
          text: 'SAVE_COMPLETE',
        });
      }
      this.allowSave = true;
    }
  }

  afterErrorSave(error, r: boolean, s: boolean, a: boolean): void {
    // tslint:disable-next-line: no-empty
    if (error.status === 500) {
      this.logService
        .postLog(LogConsts.ERROR, error.message)
        .subscribe(() => {});
      alert(
        'Fel på servern uppstod när du sparade - om du är säker på att beställningen har sparats korrekt (genom att starta om sidan till exempel) försök att generera pdf ensam.'
      );
      this.navbarService.stageActionEmitter.emit(Actions.ACTIVATE_PDF_ONLY);
    } else if (error.status === 403) {
      this.logService
        .postLog(LogConsts.ERROR, error.message)
        .subscribe(() => {});
      alert(
        'Ändringar har gjorts på produktorder. Om du är säker på att du vill behålla den här versionen klickar du på "Återvänd och spara"'
      );
      this.navbarService.stageActionEmitter.emit(Actions.SAVE_FAILED);
    } else {
      this.logService
        .postLog(LogConsts.ERROR, error.message)
        .subscribe(() => {});
      alert('Något gick fel, försök igen. ' + 'error status: ' + error.status);
      this.alertService.alertResponseEmitter.emit(Actions.REAUTHORIZE);
    }
  }

  generatePdf(): void {
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Generating pdf')
      .subscribe(() => {});
    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'GENERATING_PDF',
    });
    this.stage.width(1080);
    this.stage.height(1080);
    ZoomUtil.zoomToFit(this.stage, this.activeLayer);
    this.measurementFilterOn = true;

    this.showOuterMeasurements = false;
    this.showInnerMeasurements = true;
    this.showDrawerAlign = false;
    this.showNewCabins = true;
    this.showNames = true;
    this.sidebarSettings.zoom = 0.3;
    this.layers.forEach((layer: Layer) => {
      layer.find('.product').each((p: Product) => {
        p.modified = true;
      });
    });
    this.drawMeasurements();

    const pdf = PdfUtil.generatePdf(
      this.stage,
      this.layers,
      this.storage.get(StorageConsts.ORDER_ID),
      this.generalSettings,
      this.order.customerInfo,
      this.deviceService.isTablet()
    );
    // pdf.output('dataurlnewwindow')
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Sending pdf to HK')
      .subscribe(() => {});
    this.pdfService.uploadPdf(pdf).subscribe(
      (response) => {
        // tslint:disable-next-line: no-empty
        this.logService
          .postLog(LogConsts.INFO, 'Pdf sent successfully')
          .subscribe(() => {});
        this.navbarService.spinnerActionEmitter.emit({
          action: 'add',
          text: 'PDF_GENERATED',
        });
        this.productOrderService.orderSent();
      },
      (error) => {
        // tslint:disable-next-line: no-empty
        this.logService
          .postLog(LogConsts.ERROR, error.message)
          .subscribe(() => {});
        alert('error status: ' + error.status + error.message);
      }
    );
  }

  returnToHK(): void {
    this.productOrderService.orderSent();
  }

  initStage(): Stage {
    const stage = new Konva.Stage({
      x: 0,
      y: 0,
      container: 'container',
      width: window.innerWidth,
      height: window.innerHeight,
    });

    stage.on('dragmove', (x) => {
      Snap.snap(x, this.activeLayer, this.layers, this.config.snap);

      const ppx = Math.floor((x.target as any)?.previousPosition.x);
      const ppy = Math.floor((x.target as any)?.previousPosition.y);
      const dx = ppx ? ppx - Math.floor(x.target.attrs.x) : 0;
      const dy = ppy ? ppy - Math.floor(x.target.attrs.y) : 0;

      (x.target as any)?.updatePreviousPosition();
      x.target.position({
        x: Math.floor(x.target.attrs.x),
        y: Math.floor(x.target.attrs.y),
      });

      if (
        (x.target as any).isActive ||
        (x.target as any).isConnectedToAnother
      ) {
        this.activeLayer.find('.product').each((p: Product) => {
          //p.position();
          if (p.isConnectedToAnother || p.isActive) {
            if (p.index !== x.target.index) {
              p.move({ x: -dx, y: -dy });
            }
            p.updatePreviousPosition();
          }
        });
      }

      this.allowDraw = true;
      this.allowAutosave = true;
    });

    stage.on('dragend', (x) => {
      this.activeLayer.find('.guid-line').each((x) => x.destroy());
      this.activeLayer.batchDraw();
    });

    stage.on('click', (event) => {
      console.log(event);
      this.clickAction(this.activeLayer, event);
    });

    stage.on('touchend', (event) => {
      this.clickAction(this.activeLayer, event);
      lastDist = 0;
      lastCenter = null;
    });

    const scaleBy = 0.99;
    stage.on('wheel', (e) => {
      e.evt.preventDefault();

      if (
        this.sidebarSettings.automaticZoom ||
        this.sidebarSettings.automaticZoomOut
      ) {
        return;
      }

      const oldScale = stage.scaleX();

      const pointer = stage.getPointerPosition();

      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      const newScale =
        e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      if (newScale > 0.1 && newScale < 0.5) {
        stage.scale({ x: newScale, y: newScale });
      }
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };

      this.sidebarSettings.zoom = newScale;
      this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);

      this.allowDraw = true;

      stage.position(newPos);
      this.redrawAction();
    });

    let lastCenter = null;
    let lastDist = 0;

    stage.on('touchmove', (e) => {
      e.evt.preventDefault();

      if (
        this.sidebarSettings.automaticZoom ||
        this.sidebarSettings.automaticZoomOut
      ) {
        return;
      }

      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      if (touch1 && touch2) {
        if (stage.isDragging()) {
          stage.stopDrag();
        }

        const p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!lastCenter) {
          lastCenter = ZoomUtil.getCenter(p1, p2);
          return;
        }
        const newCenter = ZoomUtil.getCenter(p1, p2);

        const dist = ZoomUtil.getDistance(p1, p2);

        if (!lastDist) {
          lastDist = dist;
        }

        const pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX(),
        };

        const newScale = stage.scaleX() * (dist / lastDist);
        if (newScale > 0.1 && newScale < 0.5) {
          stage.scale({ x: newScale, y: newScale });
        }

        const dx = newCenter.x - lastCenter.x;
        const dy = newCenter.y - lastCenter.y;

        const newPos = {
          x: newCenter.x - pointTo.x * newScale + dx,
          y: newCenter.y - pointTo.y * newScale + dy,
        };

        this.sidebarSettings.zoom = newScale;
        this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);

        this.allowDraw = true;

        stage.position(newPos);
        this.redrawAction();

        lastDist = dist;
        lastCenter = newCenter;
      }
    });
    return stage;
  }

  private drawMeasurements(): void {
    this.layers.forEach((layer: Layer) => {
      layer.find('.product').each((product: Product) => {
        if (!this.editDrawing) {
          product.draggable(false);
        }
        product.updateProduct();
      });
    });
    // using product interactions here is misleading.
    // Product interactions, validations and measurement should be coupled in separate function.
    this.productInteractions();
    let x = ValidationUtils.validateProducts(
      this.layers,
      this.validations,
      this.bin,
      this.productConfig
    );
    x = x.concat(
      ValidationUtils.customValidations(
        this.layers,
        this.dekorlistRows,
        this.sockelRows
      )
    );
    PositionNumberUtil.setPositionNumbers(this.layers);
    this.validationService.validationMessagesEmitter.emit(x);
    this.layers.forEach((layer) => {
      Measurements.destroyLines(layer);
      if (this.showOuterMeasurements) {
        Measurements.showOutsideMeasurements(
          layer,
          this.sidebarSettings.zoom,
          this.config.fixedDimensions === ConfigConsts.YES
        );
      }
      if (this.showInnerMeasurements) {
        Measurements.showInnerMeasurements(
          layer,
          this.sidebarSettings.zoom,
          this.config.fixedDimensions,
          this.measurementFilterOn,
          this.generalSettings
        );
      }
      if (!this.showOuterMeasurements && !this.showInnerMeasurements) {
        Measurements.destroyLines(layer);
      }
    });
    if (this.activeLayer) {
      this.activeLayer.draw();
    }
  }

  productInteractions(): void {
    if (this.config) {
      ProductInteractionUtils.lightstraplistStretcher(
        this.activeLayer,
        this.config.autoWidthDekorlist
      );
      ProductInteractionUtils.fictionalLightstraplistStretcher(
        this.activeLayer,
        this.config.autoWidthDekorlist
      );
      // ProductInteractionUtils.sockelStretcher(this.activeLayer, this.config.autoWidthSockel);
      ProductInteractionUtils.changeFixedWidth(
        this.layers,
        this.bin,
        this.config.fixedDimensions === ConfigConsts.YES ? true : false
      );
      if (this.config.automaticHeatshields === ConfigConsts.YES) {
        ProductInteractionUtils.fictionalProductsInteraction(this.activeLayer);
      }
      if (this.config.ffdoorInteraction === ConfigConsts.YES) {
        ProductInteractionUtils.ffDegDoorsInteraction(this.activeLayer);
      }
      ProductInteractionUtils.sockelUpdate(this.activeLayer);
    }
    ProductInteractionUtils.commentPlacement(this.activeLayer, this.stage);
    ProductInteractionUtils.applyProductNames(this.layers, this.showNames);
    ProductInteractionUtils.drawerAlign(
      this.activeLayer,
      this.layers,
      this.showDrawerAlign
    );
    ProductInteractionUtils.applyNewCabins(this.layers, this.showNewCabins);
    ProductInteractionUtils.updateSockelSides(this.activeLayer);
  }

  // merge these two into one function
  nextLayer(): void {
    this.layerIndex += 1;
    if (this.layers.length - 1 >= this.layerIndex) {
      this.switchWalls(this.layerIndex);
    } else {
      this.layerIndex -= 1;
    }
  }

  previousLayer(): void {
    this.layerIndex -= 1;
    if (this.layerIndex >= 0) {
      this.switchWalls(this.layerIndex);
    } else {
      this.layerIndex += 1;
    }
  }
}
