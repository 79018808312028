import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Layer } from 'konva/types/Layer';
import { Stage } from 'konva/types/Stage';
import { Product } from '../model/product';
import { Wall } from '../model/wall';
import { ZoomUtil } from './zoomUtil';
import autoTable from 'jspdf-autotable';
import Konva from 'konva';
import { SubProductsUtil } from './subproductsUtil';
import { GeneralSettings } from '../model/generalSettings';
import { Mapping } from '../consts/mapping';
import { Setting } from '../model/setting';
import { CustomerInfo } from '../model/customerInfo';
import { PositionNumberUtil } from './positionNumberUtil';
import { Tag } from '../model/subproducts/tag';
import { Front } from '../model/subproducts/front';
import { Line } from 'konva/types/shapes/Line';

export class PdfUtil {
  static generatePdf(
    stage: Stage,
    layers: Layer[],
    orderId: number,
    generalSettings: GeneralSettings,
    customerInfo: CustomerInfo,
    isTablet: boolean
  ): jsPDF {
    const pdf = new jsPDF('l', 'px', 'a3', true);
    stage.position({ x: 0, y: 0 });

    // add general comment as a table

    let gposx = 0;

    layers.forEach((layer: Layer, i) => {
      pdf.setFontSize(12);
      let wall = null;
      let w = 0;
      let h = 0;
      const w2 = 850;
      const h2 = 495;

      let s = 0;

      let posx = 0;

      let subs = false;

      layer.find('.product').each((product: Product) => {
        if (product.subProducts && product.subProducts.length > 0) {
          subs = true;
        }
      });

      layer.find('.wall').each((wa: Wall) => {
        wall = wa;
        wall.attrs.width += i / 100;
        const zoom = ZoomUtil.zoomToFit(stage, layer);
        wall.clearWall();

        const a = wall.attrs.width / wall.attrs.height;
        const b = w2 / h2;
        if (a > b) {
          w = w2;
          h = w2;
          s = 0.8;
          gposx -= wall.attrs.width;
        } else {
          if (a > 1) {
            w = h2 * 1.2;
            h = h2 * 1.2;
            s = 0.6;
            gposx -= wall.attrs.width;
          } else {
            w = h2;
            h = h2;
            s = 0.8;
            if (gposx <= 0) {
              gposx = 0;
            }
            posx = gposx * stage.attrs.scaleX;
            gposx += wall.attrs.width;
          }
        }
      });

      pdf.addPage();

      let y = 1;
      let pixelCount = 124992400; // change to 124992400
      const resolution = Math.sqrt(wall.attrs.width) / 20;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ||
        isTablet
      ) {
        pixelCount = 16777216;
        y = 4;
      }
      const x = pixelCount / wall.attrs.width / wall.attrs.height;

      pdf.text('Väggnamn: ' + wall.wallName, 20, 40);
      // if (isTablet || x < 1) {
      if (true) {
        layer.find('.product').each((p: Product) => {
          let x = p.attrs.x;
          let xA = 0;
          let y = p.attrs.y;
          let width = p.WIDTH;
          let widthM = 1;
          let height = p.attrs.height;
          p.find('.tag').each((tag: Tag) => {
            if (tag.attrs.y < 0) {
              y += tag.attrs.y;
            }
            height += 35;
          });
          p.find('.drawerFillPattern').each((line: Line) => {
            line.destroy();
          });

          p.find('.front').each((front: Front) => {
            front.drawPdfFillPattern();
          });

          pdf.addImage(
            p.toDataURL({
              pixelRatio: resolution + 2,
            }),
            x * stage.attrs.scaleX * s + 20,
            y * stage.attrs.scaleX * s + wall.attrs.y + 50,
            width * stage.attrs.scaleX * s,
            height * stage.attrs.scaleX * s,
            null,
            'FAST'
          );
        });
      } else {
        pdf.addImage(
          layer.toDataURL({
            x: posx,
            y: 0,
            pixelRatio: resolution,
          }),
          20,
          wall.attrs.y + 50,
          w,
          h,
          null,
          'FAST'
        );
      }

      pdf.addPage();

      pdf.text('Ytterligare info:', 20, 40);

      if (subs) {
        pdf.text('Underprodukter: ' + wall.wallName, 20, 260);
        let isub = 0;
        const sc = 0.5;

        layer.find('.product').each((p: Product) => {
          if (p.subProducts && p.subProducts.length > 0) {
            p.updateSubproducts();
            const w = SubProductsUtil.getWidest(p);
            pdf.addImage(
              p.toDataURL({
                pixelRatio: resolution + 2,
              }),
              isub + 20,
              280,
              w * stage.attrs.scaleX * sc,
              p.getTotalHeightWithSubs() * stage.attrs.scaleX * sc,
              null,
              'FAST'
            );
            isub += w * stage.attrs.scaleX * sc + 5;
          }
        });
      }

      this.generateCommentTable(layer, pdf, true);
    });

    const n = pdf.getNumberOfPages();
    pdf.setFontSize(12);
    for (let i = 1; i <= n; i++) {
      pdf.setPage(i);
      const img = new Image();
      img.src = './../../assets/icons/logo.png';
      pdf.addImage(img, 'png', 20, 10, 153 / 2, 27 / 2);
      pdf.setTextColor(0.5);
      pdf.text('Avtalsnummer: ' + orderId.toString(), 30 + 153 / 2, 17.5);
      pdf.setTextColor(0);
      pdf.text(i.toString(), 850, 610);
    }

    pdf.setPage(1);
    this.generateCustomerTable(pdf, customerInfo);
    this.generateGeneralSettingTable(pdf, generalSettings);
    this.generateGeneralCOmmentTable(pdf, generalSettings);

    return pdf;
  }

  static generateCustomerTable(pdf, customerInfo: CustomerInfo): void {
    pdf.text('Kundinformation:', 30, 50);
    const body = [];
    if (customerInfo) {
      for (const p in customerInfo) {
        if (!p) {
          return;
        }
        if (p.toString() !== 'customerID') {
          const info = customerInfo[p.toString()]
            ? customerInfo[p.toString()]
            : '-';
          body.push([
            {
              content: this.translateCustomerInfo(p.toString()),
              styles: {
                cellWidth: 70,
                lineWidth: 0.5,
                fontSize: 12,
                cellPadding: 3,
              },
            },
            {
              content: info,
              styles: {
                cellWidth: 100,
                lineWidth: 0.5,
                fontSize: 12,
                cellPadding: 3,
              },
            },
          ]);
          console.log(p, info);
        }
      }
    }
    console.log(customerInfo);

    const customerOptions = {
      // head: [[{ content: 'Namn', styles: { fillColor: 127 } },
      // { content: 'Värde', styles: { fillColor: 127 } }
      // ]],
      body,
      tableLineColor: 50,
      tableLineWidth: 0.5,
      startY: 60,
      margin: { right: 693 },
      // rowPageBreak: 'avoid' as const,
      // pageBreak: 'avoid' as const,
      theme: 'grid' as const,
      cellPadding: 0 as const,
    };

    autoTable(pdf, customerOptions);
  }

  static generateGeneralSettingTable(
    pdf,
    generalSettings: GeneralSettings
  ): void {
    pdf.text('Allmänna Inställningar:', 220, 50);
    const body = [];
    if (generalSettings.settings) {
      generalSettings.settings.forEach((setting: Setting) => {
        if (setting.mapping && setting.mapping.name === 'GLOBAL_COMMENT') {
          return;
        }
        body.push([
          {
            content: setting.name,
            styles: {
              cellWidth: 70,
              lineWidth: 0.5,
              fontSize: 12,
              cellPadding: 3,
            },
          },
          {
            content: setting.value,
            styles: {
              cellWidth: 75,
              lineWidth: 0.5,
              fontSize: 12,
              cellPadding: 3,
            },
          },
        ]);
      });
    }
    const generalOptions = {
      head: [
        [
          { content: 'Namn', styles: { fontSize: 12, fillColor: 127 } },
          { content: 'Värde', styles: { fontSize: 12, fillColor: 127 } },
        ],
      ],
      body,
      tableLineColor: 50,
      tableLineWidth: 0.5,
      startY: 60,
      margin: { left: 220, right: 528 },
      // rowPageBreak: 'avoid' as const,
      // pageBreak: 'avoid' as const,
      theme: 'grid' as const,
      cellPadding: 0 as const,
    };
    autoTable(pdf, generalOptions);
  }

  static generateGeneralCOmmentTable(
    pdf,
    generalSettings: GeneralSettings
  ): void {
    pdf.text('Allmänna kommentar:', 385, 50);
    let body = [];

    if (generalSettings.settings) {
      generalSettings.settings.forEach((setting: Setting) => {
        if (setting.mapping && setting.mapping.name === 'GLOBAL_COMMENT') {
          const value = setting.value !== 0 ? setting.value : '-';
          body = [
            [
              {
                content: value,
                styles: { lineWidth: 0.5, fontSize: 12, cellPadding: 3 },
              },
            ],
          ];
        }
      });
    }
    const commentOptions = {
      body,
      tableLineColor: 50,
      tableLineWidth: 0.5,
      startY: 60,
      margin: { left: 385 },
      // rowPageBreak: 'avoid' as const,
      // pageBreak: 'avoid' as const,
      theme: 'grid' as const,
      cellPadding: 0 as const,
    };

    autoTable(pdf, commentOptions);
  }

  static generateCommentTable(layer: Layer, pdf, top: boolean): void {
    const comments: any[] = [];
    let productsWComments: Product[] = [];
    layer.find('.product').each((product: Product) => {
      productsWComments.push(product);
    });

    // sort by positionnumber
    productsWComments =
      PositionNumberUtil.sortByPositionnumber(productsWComments);

    productsWComments.forEach((product: Product, i) => {
      const positionnumber =
        product.productSettings && product.productSettings.POSITIONNUMBER
          ? product.productSettings.POSITIONNUMBER
          : '-';
      const name = product.type ? product.type : '-';
      const color =
        product.additionalInfo && product.additionalInfo.color
          ? product.additionalInfo.color
          : '-';
      const edge =
        product.additionalInfo && product.additionalInfo.edge
          ? product.additionalInfo.edge
          : '-';
      const handle =
        product.additionalInfo && product.additionalInfo.handle
          ? product.additionalInfo.handle
          : '-';
      const material =
        product.additionalInfo && product.additionalInfo.material
          ? product.additionalInfo.material
          : '-';
      const comment =
        product.productSettings && product.productSettings.COMMENTS
          ? product.productSettings.COMMENTS
          : '-';

      comments.push([
        {
          content: positionnumber,
          styles: {
            cellWidth: 30,
            lineWidth: 0.5,
            fontSize: 8,
            cellPadding: 3,
          },
        },
        {
          content: name,
          styles: {
            cellWidth: 120,
            lineWidth: 0.5,
            fontSize: 8,
            cellPadding: 3,
          },
        },
        {
          content: color,
          styles: {
            cellWidth: 50,
            lineWidth: 0.5,
            fontSize: 8,
            cellPadding: 3,
          },
        },
        {
          content: edge,
          styles: {
            cellWidth: 50,
            lineWidth: 0.5,
            fontSize: 8,
            cellPadding: 3,
          },
        },
        {
          content: handle,
          styles: {
            cellWidth: 50,
            lineWidth: 0.5,
            fontSize: 8,
            cellPadding: 3,
          },
        },
        {
          content: material,
          styles: {
            cellWidth: 50,
            lineWidth: 0.5,
            fontSize: 8,
            cellPadding: 3,
          },
        },
        {
          content: comment,
          styles: { lineWidth: 0.5, fontSize: 8, cellPadding: 3 },
        },
      ]);
    });

    if (comments.length > 0) {
      const headers = [
        { content: 'Pos', styles: { fillColor: 127 } },
        { content: 'Namn', styles: { fillColor: 127 } },
        { content: 'Färg', styles: { fillColor: 127 } },
        { content: 'Kant', styles: { fillColor: 127 } },
        { content: 'Handtag', styles: { fillColor: 127 } },
        { content: 'Mönster', styles: { fillColor: 127 } },
        { content: 'Kommentar', styles: { fillColor: 127 } },
      ];

      let y = 0;
      if (top) {
        y = 50;
      } else {
        y = 520;
      }

      const options = {
        head: [headers],
        body: comments,
        tableLineColor: 50,
        tableLineWidth: 0.5,
        startY: y,
        startX: 50,
        // rowPageBreak: 'avoid' as const,
        // pageBreak: 'avoid' as const,
        theme: 'grid' as const,
        cellPadding: 0 as const,
      };
      autoTable(pdf, options);
    }
  }

  static translateCustomerInfo(text): string {
    switch (text) {
      case 'address':
        return 'Adress';
      case 'customerID':
        return 'Kundnummer';
      case 'email':
        return 'Email';
      case 'firstname':
        return 'Förnamn';
      case 'lastname':
        return 'Efternamn';
      case 'phone':
        return 'telefon';
      case 'phone2':
        return 'telefon2';
      case 'postalAddress':
        return 'Postadress';
      case 'postalCode':
        return 'Postnummer';
      case '':
        return '';
    }
    return '';
  }
}
